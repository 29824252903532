<script lang="ts" setup>
import Icon from '@design-system/components/Icon/Icon.vue'
import type { PropType } from 'vue'
import type { TheTabBarPropMenu, TheTabBarPropMenus } from './types'

defineProps({
  menus: {
    type: Array as PropType<TheTabBarPropMenus>,
    required: true,
  },
})

const route = useRoute()
const localeRoute = useLocaleRoute()
const localePath = useLocalePath()
const { locale } = useI18n()

const isActiveRoute = (menu: TheTabBarPropMenu) => {
  const localizedRoute = localeRoute(menu.path, locale.value)
  const path = localizedRoute != null ? localizedRoute.path : '/'

  if (menu.exact) {
    return route.path === path
  }

  return route.path.startsWith(path)
}
</script>

<template>
  <div
    class="bg-cinza-liti-300 fixed bottom-0 left-0 right-0 z-50 bg-opacity-80 py-2 pb-[calc(8px+env(safe-area-inset-bottom))] backdrop-blur-2xl backdrop-saturate-[180%]"
  >
    <ul class="flex w-full justify-evenly">
      <li v-for="(menu, index) in menus" :key="index">
        <NuxtLink :to="localePath(menu.path)" class="group relative flex items-center rounded-lg transition">
          <span
            class="inline-flex items-center rounded-lg p-2 transition group-hover:bg-purple-900"
            :class="{ 'bg-purple-900': isActiveRoute(menu) }"
          >
            <Icon
              v-if="menu.icon"
              class="h-6 w-6 transition group-hover:text-white"
              :class="isActiveRoute(menu) ? 'text-white' : 'text-purple-900'"
              :icon="menu.icon"
            />
            <span v-else class="group-hover:text-white" :class="{ 'text-white ': isActiveRoute(menu) }">
              {{ menu.title }}
            </span>
          </span>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
