<script setup lang="ts">
const eventsAnalytics = useEventsAnalytics()
</script>

<template>
  <div>
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-between bg-gray-200 px-4 pt-4 pb-2">
      <span class="text-base font-semibold text-gray-900">
        {{ $t('WarningBanner.title') }}
      </span>
    </div>
    <!-- Body -->
    <div class="flex items-center bg-gray-200 px-4 pb-4 overflow-y-auto">
      <p class="text-sm text-gray-900">
        {{ $t('WarningBanner.message') }}
      </p>
    </div>
    <!-- Footer -->
    <div class="flex items-center justify-end shrink-0 bg-gray-200 px-4 pb-4 space-x-2">
      <LButton
        variant="link"
        size="sm"
        class="border border-primary"
        target="_blank"
        to="https://docs.google.com/document/d/16hbMeBjVUicM3A4gtnNGGgUVSDJdydPu5mTyrdrdR2A/edit"
        @click="eventsAnalytics.track('Click Warning Action')"
      >
        {{ $t('general.seeMore') }}
      </LButton>
    </div>
  </div>
</template>
