<script lang="ts" setup>
import Icon from '@design-system/components/Icon/Icon.vue'

import { isEqual, withTrailingSlash } from 'ufo'
import type { PropType } from 'vue'
import type { TheSidebarPropCollapsed, TheSidebarPropMenu, TheSidebarPropMenus } from './types'

const props = defineProps({
  collapsed: {
    type: Boolean as PropType<TheSidebarPropCollapsed>,
    default: false,
  },
  menus: {
    type: Array as PropType<TheSidebarPropMenus>,
    required: true,
  },
})

const isCollapsed = computed(() => Boolean(props.collapsed))
const isExpanded = computed(() => Boolean(!props.collapsed))

const route = useRoute()
const localeRoute = useLocaleRoute()
const localePath = useLocalePath()
const { locale } = useI18n()

const events = useEventsAnalytics()

const isActiveRoute = (menu: TheSidebarPropMenu) => {
  const localizedRoute = localeRoute(menu.path, locale.value)
  const path = localizedRoute != null ? localizedRoute.path : '/'

  if (menu.exact) {
    return isEqual(route.path, path)
  }

  return withTrailingSlash(route.path).startsWith(withTrailingSlash(path))
}

const hasIcon = computed(() => props.menus?.every((menu) => !!menu.icon) || false)
</script>

<template>
  <aside
    class="relative flex justify-between h-screen transform-gpu flex-col overflow-x-hidden bg-white transition-all will-change-transform"
    :class="{
      'w-60': isExpanded,
      'w-0 md:w-28': isCollapsed,
    }"
    aria-label="Sidebar"
  >
    <div v-if="$slots.header" class="flex justify-center bg-white px-9 py-2">
      <slot name="header" />
    </div>
    <!--
      Position of menu items:

      Center: my-auto py-4
      Top: $slots.header ? 'pt-24 pb-4' : 'mt-[60px] py-4'
      -->
    <div
      class="snap-y snap-mandatory overflow-y-auto overflow-x-hidden scroll-smooth px-9"
      :class="$slots.header ? 'pb-4 pt-24' : 'mt-[60px] py-4'"
    >
      <ul :class="{ 'space-y-8': hasIcon, 'space-y-4': !hasIcon }">
        <li v-for="menu in menus" :key="menu.title">
          <NuxtLink
            :to="localePath(menu.path)"
            class="group relative flex snap-start scroll-my-4 items-center rounded-lg text-base font-normal text-gray-900 transition duration-150 hover:bg-liti-light-detail"
            :class="{
              'pr-2': isExpanded,
              'bg-liti-light-primary hover:bg-liti-light-primary-hover dark:bg-liti-dark-primary dark:hover:bg-liti-dark-primary-hover':
                !hasIcon && isActiveRoute(menu),
              'py-2': !hasIcon,
            }"
            @click="menu.event && events.track(menu.event)"
          >
            <span
              v-if="hasIcon"
              class="group-hover:bg-liti-light-primary dark:group-hover:bg-liti-dark-primary inline-flex items-center rounded-lg p-2 transition duration-150"
              :class="{ 'bg-liti-light-primary dark:bg-liti-dark-primary': isActiveRoute(menu) }"
            >
              <Icon
                class="h-6 w-6 transition duration-150 group-hover:bg-liti-light-text-button"
                :class="isActiveRoute(menu) ? 'text-white' : 'text-primary'"
                :icon="menu.icon!"
              />
            </span>

            <span
              class="ml-3 origin-left transition duration-150"
              :class="{
                hidden: collapsed,
                'text-white': !hasIcon && isActiveRoute(menu),
              }"
            >
              {{ menu.title }}
            </span>
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div class="space-y-2 pb-8">
      <WarningBanner />
    </div>
  </aside>
</template>
